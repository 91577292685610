import tableState from "../../templates/table/state";
import moment from "moment";
import { cloneDeep } from "lodash";

const baseFilters = {
  dataTypes: "unique_users",
  sort: "unique_users",
  from: moment()
    .startOf("month")
    .subtract(12, "months")
    .format("YYYY-MM"),
  to: moment()
    .subtract(1, "months")
    .format("YYYY-MM"),
  minimumVisitors: 1000,
  minimumConversions: 10
};

export default {
  ...tableState(cloneDeep(baseFilters)),
  baseFilters: cloneDeep(baseFilters)
};
